import React from "react";
import HeroNoImage from "../components/hero-no-image";
import Layout from "../components/layout";
import SEO from "../components/seo";

import Container from "../components/container";

import "../styles/layout.scss";

const ContactPage = props => {
  return (
    <Layout>
      <SEO title="404 - Not found" />
      <HeroNoImage />
      <Container>
        <div className="about-page">
          <h1>
            Ånei! du har truffet en rute <br /> som ikke eksisterer :(
          </h1>
        </div>
      </Container>
    </Layout>
  );
};

export default ContactPage;
